<template>
  <div class="orderlist">
    <van-nav-bar fixed placeholder left-arrow @click-left="onClickLeft">
      <template #title>
        <van-search
          placeholder="请输入搜索关键词"
          shape="round"
          readonly
          @click="onSearch"
        >
        </van-search>
      </template>
      <template #right>
        <div @click="onSearch">搜索</div>
      </template>
    </van-nav-bar>
    <van-tabs
      v-model="orderactive"
      sticky
      offset-top="44"
      swipe-threshold="6"
      title-active-color="#ee0a24"
      @click="tabsClick"
      :duration="0.1"
    >
      <van-tab :style="'min-height:' + (screeHeight - 150) + 'px; padding-bottom: 50px;'" title="全部">
        <div class="main" v-if="list.length > 0 ? true: false">
          <van-list
            v-model="loading[orderactive]"
            :finished="finished[orderactive]"
            finished-text="没有更多了"
            @load="onLoad()"
            :immediate-check="false"
          >
            <div class="order_list" v-for="(item, index) in list" :key="index">
              <van-cell :border="false">
                <template #title>
                  {{item.ordersn}}
                  <input type="text" v-model="item.ordersn" v-show="false" />
                  <van-button
                    v-clipboard:copy="item.ordersn"
                    v-clipboard:success="ordersnonCopy"
                    v-clipboard:error="ordersnError"
                    size="mini"
                  >
                    复制
                  </van-button>
                </template>
                <template #right-icon>
                  <span class="red">{{ item.status_str }}</span>
                </template>
              </van-cell>
              <div v-if="item.goods && item.goods.length > 0">
                <van-card
                  v-for="(v,k) in item.goods"
                  :key="k"
                  :num="v.total"
                  :price="v.realprice"
                  :title="v.goodstitle"
                  :thumb="v.goodsthumb"
                  @click="details(index, k)"
                  lazy-load
                >
                  <template #desc>
                    <div>
                      {{v.optionname}}
                      <van-tag color="#9168ed" text-color="#fff" v-if="v.yushouTime">{{v.yushouTime}}</van-tag>
                    </div>
                  </template>
                  <template #tag>
                    {{strId}}
                  </template>
                </van-card>
              </div>
              <van-cell title="">
                <template #right-icon>实付：&yen;{{item.price}}({{ item.dispatchprice > 0 ? "运费：" + item.dispatchprice + "元" : "免运费" }})</template>
              </van-cell>
              <van-cell title>
                <template #right-icon>
                  <van-button
                    v-if="item.status > 0 && item.status <2"
                    @click="cuidan(item.ordersn)"
                    size="small"
                    plain
                    hairline
                  >催单</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="reFundHistory(item.id)"
                    v-if="item.bc_list_btn == 1"
                  >退款记录</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="miniTuikuan(item.id)"
                    v-if="item.bc_go_btn == 1"
                  >申请退款</van-button>
                  <van-button
                    size="small"
                    v-if="item.bc_go_btn == 2"
                    plain
                    hairline
                    @click="reFund(index)"
                  >{{item.is_saleservice > 0 && item.is_saleservice != 2 ? '售后中' : '申请售后'}}</van-button>
                  <van-button
                    v-if="item.is_service_btn"
                    size="small"
                    plain
                    hairline
                    @click="reFund(index)"
                  >{{item.is_service_btn}}</van-button>
                  <van-button
                    v-if="item.is_service_btn2"
                    size="small"
                    plain
                    hairline
                    @click="cancelRefund(index)"
                  >{{item.is_service_btn2}}</van-button>
                  <van-button
                    size="small"
                    v-if="item.status == 0"
                    @click="toPay(item.id, index)"
                  >去支付</van-button>
                  <van-button
                    size="small"
                    v-if="item.status == 0"
                    @click="cancelPay(item.id, index)"
                  >删除订单</van-button>
                  <van-button
                    size="small"
                    type="danger"
                    plain
                    hairline
                    @click="toGoods(item.goods[0].goodsid, index)"
                  >再来一单</van-button>
                </template>
              </van-cell>
              <van-cell
                :border="false"
                v-if="item.is_saleservice_content && item.is_saleservice_content.length > 0"
                is-link
                class="shouH_cell"
                @click="serviceDetail(item.id)"
              >
                <template #title>
                  <div class="red">
                    售后内容: {{item.is_saleservice_content}}
                    <van-tag class="copay_icon" v-if="item.is_saleservice > 0 && item.is_saleservice != 2" plain @click="addserviceInfo(index)" >{{(item.rtype == 2 || item.rtype == 1 || item.rtype == 3) && item.progress == 3 ? '添加单号': '回复'}}</van-tag>
                  </div>
                </template>
                <template #default>
                  <div>
                    查看
                  </div>
                </template>
              </van-cell>
              <van-cell :border="false" v-if="item.check_status == 2">
                <template #title>
                  <div style="color: #ee0a24;">
                    请退款{{item.special_remark ? "：" + item.special_remark : ""}}
                  </div>
                </template>
              </van-cell>
              <van-cell-group :border="false" class="address_info">
                <van-cell :border="false">
                  <template #title>
                    {{item.contact_name ? item.contact_name : ""}}
                  </template>
                  <template #default>
                    {{item.mobile ? item.mobile : ""}}
                    <van-button
                      v-clipboard:copy="item.contact_name + ' ' + item.mobile + ' ' + item.province + ' ' + item.city + ' ' + item.county + ' ' + item.street"
                      v-clipboard:success="ordersnonCopy"
                      v-clipboard:error="ordersnError"
                      size="mini"
                    >
                      复制
                    </van-button>
                  </template>
                </van-cell>
                <van-cell :border="false" title title-class="tongchang">
                  <template #title>
                    {{item.province ? item.province : ""}}
                    {{item.city ? item.city : ""}}
                    {{item.county ? item.county : ""}}
                    {{item.street ? item.street : item.address}}
                  </template>
                </van-cell>
              </van-cell-group>
              <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
                <van-button
                  block
                  icon="service-o"
                  @click="qiyukefu(index)"
                >
                  联系客服
                </van-button>
              </div>
            </div>
          </van-list>
        </div>
        <div class="main" v-if="shownodata[orderactive]">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
      <van-tab :style="'min-height:' + (screeHeight - 150) + 'px; padding-bottom: 100px;'" title="待付款">
        <div class="main" v-if="list.length > 0 ? true: false">
          <van-checkbox-group v-model="result" ref="checkboxGroup">
            <van-list
              v-model="loading[orderactive]"
              :finished="finished[orderactive]"
              finished-text="没有更多了"
              @load="onLoad()"
              :immediate-check="false"
            >
              <div class="order_list" v-for="(item, index) in list" :key="index">
                <van-cell :border="false" @click="checkboxChange(index)">
                  <template #icon>
                    <van-checkbox :name="index" checked-color="#d22625" ref="checkboxes"></van-checkbox>
                  </template>
                  <template #title>
                    {{item.ordersn}}
                    <input type="text" v-model="item.ordersn" v-show="false" />
                    <van-button
                      v-clipboard:copy="item.ordersn"
                      v-clipboard:success="ordersnonCopy"
                      v-clipboard:error="ordersnError"
                      size="mini"
                    >
                      复制
                    </van-button>
                  </template>
                  <template #right-icon>
                    <span class="red">{{ item.status_str }}</span>
                  </template>
                </van-cell>
                <div v-if="item.goods && item.goods.length > 0">
                  <van-card
                    v-for="(v,k) in item.goods"
                    :key="k"
                    :num="v.total"
                    :price="v.realprice"
                    :title="v.goodstitle"
                    :thumb="v.goodsthumb"
                    @click="details(index, k)"
                    lazy-load
                  >
                    <template #desc>
                      <div>
                        {{v.optionname}}
                        <van-tag color="#9168ed" text-color="#fff" v-if="v.yushouTime">{{v.yushouTime}}</van-tag>
                      </div>
                    </template>
                    <template #tag>
                      {{strId}}
                    </template>
                  </van-card>
                </div>
                <van-cell title="">
                  <template #right-icon>实付：&yen;{{item.price}}({{ item.dispatchprice > 0 ? "运费：" + item.dispatchprice + "元" : "免运费" }})</template>
                </van-cell>
                <van-cell title>
                  <template #right-icon>
                    <van-button size="small" v-if="item.status == 0" @click="toPay(item.id, index)">去支付</van-button>
                    <van-button size="small" v-if="item.status == 0" @click="cancelPay(item.id, index)">删除订单</van-button>
                    <van-button size="small" type="danger" plain hairline @click="toGoods(item.goods[0].goodsid, index)">再来一单</van-button>
                  </template>
                </van-cell>
                <van-cell-group :border="false" class="address_info">
                  <van-cell :border="false">
                    <template #title>
                      {{item.contact_name ? item.contact_name : ""}}
                    </template>
                    <template #default>
                      {{item.mobile ? item.mobile : ""}}
                      <van-button
                        v-clipboard:copy="item.contact_name + ' ' + item.mobile + ' ' + item.province + ' ' + item.city + ' ' + item.county + ' ' + item.street"
                        v-clipboard:success="ordersnonCopy"
                        v-clipboard:error="ordersnError"
                        size="mini"
                      >
                        复制
                      </van-button>
                    </template>
                  </van-cell>
                  <van-cell :border="false" title title-class="tongchang">
                    <template #title>
                      {{item.province ? item.province : ""}}
                      {{item.city ? item.city : ""}}
                      {{item.county ? item.county : ""}}
                      {{item.street ? item.street : item.address}}
                    </template>
                  </van-cell>
                </van-cell-group>
                <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
                  <van-button
                    block
                    icon="service-o"
                    @click="qiyukefu(index)"
                  >
                    联系客服
                  </van-button>
                </div>
              </div>
            </van-list>
          </van-checkbox-group>
        </div>
        <div class="main"  v-if="shownodata[orderactive]">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
      <van-tab :style="'min-height:' + (screeHeight - 150) + 'px; padding-bottom: 100px;'" title="待审核">
        <div class="main" v-if="list.length > 0 ? true: false">
          <van-list
            v-model="loading[orderactive]"
            :finished="finished[orderactive]"
            finished-text="没有更多了"
            @load="onLoad()"
            :immediate-check="false"
          >
            <div class="order_list" v-for="(item, index) in list" :key="index">
              <van-cell :border="false" @click="checkboxChange(index)">
                <template #title>
                  {{item.ordersn}}
                  <input type="text" v-model="item.ordersn" v-show="false" />
                  <van-button
                    v-clipboard:copy="item.ordersn"
                    v-clipboard:success="ordersnonCopy"
                    v-clipboard:error="ordersnError"
                    size="mini"
                  >
                    复制
                  </van-button>
                </template>
                <template #right-icon>
                  <span class="red">{{ item.check_status_str }}</span>
                </template>
              </van-cell>
              <div v-if="item.goods && item.goods.length > 0">
                <van-card
                  v-for="(v,k) in item.goods"
                  :key="k"
                  :num="v.total"
                  :price="v.realprice"
                  :title="v.goodstitle"
                  :thumb="v.goodsthumb"
                  @click="details(index, k)"
                  lazy-load
                >
                  <template #desc>
                    <div>
                      {{v.optionname}}
                      <van-tag color="#9168ed" text-color="#fff" v-if="v.yushouTime">{{v.yushouTime}}</van-tag>
                    </div>
                  </template>
                  <template #tag>
                    {{strId}}
                  </template>
                </van-card>
              </div>
              <van-cell title="">
                <template #right-icon>实付：&yen;{{item.price}}({{ item.dispatchprice > 0 ? "运费：" + item.dispatchprice + "元" : "免运费" }})</template>
              </van-cell>
              <van-cell title>
                <template #right-icon>
                  <van-button size="small" v-if="item.status == 0" @click="toPay(item.id, index)">去支付</van-button>
                  <van-button size="small" v-if="item.status == 0" @click="cancelPay(item.id, index)">删除订单</van-button>
                  <van-button v-if="item.status > 0 && item.status < 2" @click="cuidan(item.ordersn)" size="small" plain hairline>催单</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="reFundHistory(item.id)"
                    v-if="item.bc_list_btn == 1"
                  >退款记录</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="miniTuikuan(item.id)"
                    v-if="item.bc_go_btn == 1"
                  >申请退款</van-button>
                  <van-button
                    size="small"
                    v-if="item.bc_go_btn == 2"
                    plain
                    hairline
                    @click="reFund(index)"
                  >申请售后</van-button>
                  <van-button size="small" type="danger" plain hairline @click="toGoods(item.goods[0].goodsid, index)">再来一单</van-button>
                </template>
              </van-cell>
              <van-cell :border="false"  v-if="item.is_saleservice_content && item.is_saleservice_content.length > 0" is-link class="shouH_cell" @click="serviceDetail(item.id)">
                <template #title>
                  <div class="red">
                    售后内容: {{item.is_saleservice_content}}
                    <van-tag class="copay_icon" v-if="item.is_saleservice > 0 && item.is_saleservice != 2" plain @click="addserviceInfo(index)" >{{(item.rtype == 2 || item.rtype == 1 || item.rtype == 3) && item.progress == 3 ? '添加单号': '回复'}}</van-tag>
                  </div>
                </template>
                <template #default>
                  <div>
                    查看
                  </div>
                </template>
              </van-cell>
              <van-cell-group :border="false" class="address_info">
                <van-cell :border="false">
                  <template #title>
                    {{item.contact_name ? item.contact_name : ""}}
                  </template>
                  <template #default>
                    {{item.mobile ? item.mobile : ""}}
                    <van-button
                      v-clipboard:copy="item.contact_name + ' ' + item.mobile + ' ' + item.province + ' ' + item.city + ' ' + item.county + ' ' + item.street"
                      v-clipboard:success="ordersnonCopy"
                      v-clipboard:error="ordersnError"
                      size="mini"
                    >
                      复制
                    </van-button>
                  </template>
                </van-cell>
                <van-cell :border="false" title title-class="tongchang">
                  <template #title>
                    {{item.province ? item.province : ""}}
                    {{item.city ? item.city : ""}}
                    {{item.county ? item.county : ""}}
                    {{item.street ? item.street : item.address}}
                  </template>
                </van-cell>
              </van-cell-group>
              <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
                <van-button
                  block
                  icon="service-o"
                  @click="qiyukefu(index)"
                >
                  联系客服
                </van-button>
              </div>
            </div>
          </van-list>

        </div>
        <div class="main"  v-if="shownodata[orderactive]">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
      <van-tab :style="'min-height:' + (screeHeight - 150) + 'px; padding-bottom: 50px;'" title="待发货">
        <div class="main" v-if="list.length > 0 ? true: false">
          <van-list
            v-model="loading[orderactive]"
            :finished="finished[orderactive]"
            finished-text="没有更多了"
            @load="onLoad()"
            :immediate-check="false"
          >
            <div class="order_list" v-for="(item, index) in list" :key="index">
              <van-cell :border="false">
                <template #title>
                  {{item.ordersn}}
                  <input type="text" v-model="item.ordersn" v-show="false" />
                  <van-button
                    v-clipboard:copy="item.ordersn"
                    v-clipboard:success="ordersnonCopy"
                    v-clipboard:error="ordersnError"
                    size="mini"
                  >
                    复制
                  </van-button>
                </template>
                <template #right-icon>
                  <span class="red">{{ item.status_str }}</span>
                </template>
              </van-cell>
              <div v-if="item.goods && item.goods.length > 0">
                <van-card
                  v-for="(v,k) in item.goods"
                  :key="k"
                  :num="v.total"
                  :price="v.realprice"
                  :title="v.goodstitle"
                  :thumb="v.goodsthumb"
                  @click="details(index, k)"
                  lazy-load
                >
                  <template #desc>
                    <div>
                      {{v.optionname}}
                      <van-tag color="#9168ed" text-color="#fff" v-if="v.yushouTime">{{v.yushouTime}}</van-tag>
                    </div>
                  </template>
                  <template #footer>{{v.message}}</template>
                  <template #tag>
                    {{strId}}
                  </template>
                </van-card>
              </div>
              <van-cell title="">
                <template #right-icon>实付：&yen;{{item.price}}({{ item.dispatchprice > 0 ? "运费：" + item.dispatchprice + "元" : "免运费" }})</template>
              </van-cell>
              <van-cell title>
                <template #right-icon>
                  <van-button v-if="item.status > 0 && item.status <2" @click="cuidan(item.ordersn)" size="small" plain hairline>催单</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="reFundHistory(item.id)"
                    v-if="item.bc_list_btn == 1"
                  >退款记录</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="miniTuikuan(item.id)"
                    v-if="item.bc_go_btn == 1"
                  >申请退款</van-button>
                  <van-button
                    size="small"
                    v-if="item.bc_go_btn == 2"
                    plain
                    hairline
                    @click="reFund(index)"
                  >申请售后</van-button>
                  <van-button v-if="item.is_service_btn" size="small" plain hairline @click="reFund(index)">{{item.is_service_btn}}</van-button>
                  <van-button v-if="item.is_service_btn2" size="small" plain hairline @click="cancelRefund(index)">{{item.is_service_btn2}}</van-button>
                  <van-button size="small" type="danger" plain hairline @click="toGoods(item.goods[0].goodsid, index)">再来一单</van-button>
                </template>
              </van-cell>
              <van-cell :border="false"  v-if="item.is_saleservice_content && item.is_saleservice_content.length > 0" is-link class="shouH_cell" @click="serviceDetail(item.id)">
                <template #title>
                  <div class="red">
                    售后内容: {{item.is_saleservice_content}}
                    <van-tag class="copay_icon" v-if="item.is_saleservice > 0 && item.is_saleservice != 2" plain @click="addserviceInfo(index)" >{{(item.rtype == 2 || item.rtype == 1 || item.rtype == 3) && item.progress == 3 ? '添加单号': '回复'}}</van-tag>
                  </div>
                </template>
                <template #default>
                  <div>
                    查看
                  </div>
                </template>
              </van-cell>
              <van-cell-group :border="false" class="address_info">
                <van-cell :border="false">
                  <template #title>
                    {{item.contact_name ? item.contact_name : ""}}
                  </template>
                  <template #default>
                    {{item.mobile ? item.mobile : ""}}
                    <van-button
                      v-clipboard:copy="item.contact_name + ' ' + item.mobile + ' ' + item.province + ' ' + item.city + ' ' + item.county + ' ' + item.street"
                      v-clipboard:success="ordersnonCopy"
                      v-clipboard:error="ordersnError"
                      size="mini"
                    >
                      复制
                    </van-button>
                  </template>
                </van-cell>
                <van-cell :border="false" title title-class="tongchang">
                  <template #title>
                    {{item.province ? item.province : ""}}
                    {{item.city ? item.city : ""}}
                    {{item.county ? item.county : ""}}
                    {{item.street ? item.street : item.address}}
                  </template>
                </van-cell>
              </van-cell-group>
              <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
                <van-button
                  block
                  icon="service-o"
                  @click="qiyukefu(index)"
                >
                  联系客服
                </van-button>
              </div>
            </div>
          </van-list>
        </div>
        <div class="main"  v-if="shownodata[orderactive]">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
      <van-tab :style="'min-height:' + (screeHeight - 150) + 'px; padding-bottom: 50px;'" title="待收货">
        <div class="main" v-if="list.length > 0 ? true: false">
          <van-list
            v-model="loading[orderactive]"
            :finished="finished[orderactive]"
            finished-text="没有更多了"
            @load="onLoad()"
            :immediate-check="false"
          >
            <div class="order_list" v-for="(item, index) in list" :key="index">
              <van-cell :border="false">
                <template #title>
                  {{item.ordersn}}
                  <input type="text" v-model="item.ordersn" v-show="false" />
                  <van-button
                    v-clipboard:copy="item.ordersn"
                    v-clipboard:success="ordersnonCopy"
                    v-clipboard:error="ordersnError"
                    size="mini"
                  >
                    复制
                  </van-button>
                </template>
                <template #right-icon>
                  <span class="red">{{ item.status_str }}</span>
                </template>
              </van-cell>
              <div v-if="item.goods && item.goods.length > 0">
                <van-card
                  v-for="(v,k) in item.goods"
                  :key="k"
                  :num="v.total"
                  :price="v.realprice"
                  :title="v.goodstitle"
                  :thumb="v.goodsthumb"
                  @click="details(index, k)"
                  lazy-load
                >
                  <template #desc>
                    <div>
                      {{v.optionname}}
                      <van-tag color="#9168ed" text-color="#fff" v-if="v.yushouTime">{{v.yushouTime}}</van-tag>
                    </div>
                  </template>
                  <template #tag>
                    {{strId}}
                  </template>
                </van-card>
              </div>
              <van-cell title="">
                <template #right-icon>实付：&yen;{{item.price}}({{ item.dispatchprice > 0 ? "运费：" + item.dispatchprice + "元" : "免运费" }})</template>
              </van-cell>
              <van-cell title>
                <template #right-icon>
                  <van-button v-if="item.status > 0 && item.status <2" @click="cuidan(item.ordersn)" size="small" plain hairline>催单</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="reFundHistory(item.id)"
                    v-if="item.bc_list_btn == 1"
                  >退款记录</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="miniTuikuan(item.id)"
                    v-if="item.bc_go_btn == 1"
                  >申请退款</van-button>
                  <van-button
                    size="small"
                    v-if="item.bc_go_btn == 2"
                    plain
                    hairline
                    @click="reFund(index)"
                  >申请售后</van-button>
                  <van-button v-if="item.is_service_btn" size="small" plain hairline @click="reFund(index)">{{item.is_service_btn}}</van-button>
                  <van-button v-if="item.is_service_btn2" size="small" plain hairline @click="cancelRefund(index)">{{item.is_service_btn2}}</van-button>
                  <van-button size="small" type="danger" plain hairline @click="toGoods(item.goods[0].goodsid, index)">再来一单</van-button>
                  <van-button size="small" @click="Receipt(item.id, index)">确认收货</van-button>
                </template>
              </van-cell>
              <van-cell :border="false" v-if="item.is_saleservice_content && item.is_saleservice_content.length > 0">
                <template #title>
                  <div class="red">
                    {{item.is_saleservice_content}}
                    <van-tag class="copay_icon" v-if="item.is_saleservice > 0 && item.is_saleservice != 2" plain @click="addserviceInfo(index)">{{(item.rtype == 2 || item.rtype == 1 || item.rtype == 3) && item.progress == 3 ? '添加单号': '回复'}}</van-tag>
                  </div>
                </template>
              </van-cell>
              <van-cell v-if="item.expresssn" :title="'快递单号：' + item.expresssn">
                <template #right-icon>
                  <input type="text" v-model="item.expresssn" v-show="false" />
                  <van-button
                    size="small"
                    v-clipboard:copy="item.expresssn"
                    v-clipboard:success="expressnonCopy"
                    v-clipboard:error="expressnonError"
                  >复制</van-button>
                </template>
              </van-cell>
              <van-cell-group :border="false" class="address_info">
                <van-cell :border="false">
                  <template #title>
                    {{item.contact_name ? item.contact_name : ""}}
                  </template>
                  <template #default>
                    {{item.mobile ? item.mobile : ""}}
                    <van-button
                      v-clipboard:copy="item.contact_name + ' ' + item.mobile + ' ' + item.province + ' ' + item.city + ' ' + item.county + ' ' + item.street"
                      v-clipboard:success="ordersnonCopy"
                      v-clipboard:error="ordersnError"
                      size="mini"
                    >
                      复制
                    </van-button>
                  </template>
                </van-cell>
                <van-cell :border="false" title title-class="tongchang">
                  <template #title>
                    {{item.province ? item.province : ""}}
                    {{item.city ? item.city : ""}}
                    {{item.county ? item.county : ""}}
                    {{item.street ? item.street : item.address}}
                  </template>
                </van-cell>
              </van-cell-group>
              <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
                <van-button
                  block
                  icon="service-o"
                  @click="qiyukefu(index)"
                >
                  联系客服
                </van-button>
              </div>
            </div>
          </van-list>
        </div>
        <div class="main"  v-if="shownodata[orderactive]">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
      <van-tab :style="'min-height:' + (screeHeight - 150) + 'px; padding-bottom: 50px;'" title="已完成">
        <div class="main" v-if="list.length > 0 ? true: false">
          <van-list
            v-model="loading[orderactive]"
            :finished="finished[orderactive]"
            finished-text="没有更多了"
            @load="onLoad()"
            :immediate-check="false"
          >
            <div class="order_list" v-for="(item, index) in list" :key="index">
              <van-cell :border="false">
                <template #title>
                  {{item.ordersn}}
                  <input type="text" v-model="item.ordersn" v-show="false" />
                  <van-button
                    v-clipboard:copy="item.ordersn"
                    v-clipboard:success="ordersnonCopy"
                    v-clipboard:error="ordersnError"
                    size="mini"
                  >
                    复制
                  </van-button>
                </template>
                <template #right-icon>
                  <span class="red">{{ item.status_str }}</span>
                </template>
              </van-cell>
              <div v-if="item.goods && item.goods.length > 0">
                <van-card
                  v-for="(v,k) in item.goods"
                  :key="k"
                  :num="v.total"
                  :price="v.realprice"
                  :title="v.goodstitle"
                  :thumb="v.goodsthumb"
                  @click="details(index, k)"
                  lazy-load
                >
                  <template #desc>
                    <div>
                      {{v.optionname}}
                      <van-tag color="#9168ed" text-color="#fff" v-if="v.yushouTime">{{v.yushouTime}}</van-tag>
                    </div>
                  </template>
                  <template #tag>
                    {{strId}}
                  </template>
                </van-card>
              </div>
              <van-cell title="">
                <template #right-icon>实付：&yen;{{item.price}}({{ item.dispatchprice > 0 ? "运费：" + item.dispatchprice + "元" : "免运费" }})</template>
              </van-cell>
              <van-cell title>
                <template #right-icon>
                  <van-button v-if="item.status > 0 && item.status <2" @click="cuidan(item.ordersn)" size="small" plain hairline>催单</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="reFundHistory(item.id)"
                    v-if="item.bc_list_btn == 1"
                  >退款记录</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="miniTuikuan(item.id)"
                    v-if="item.bc_go_btn == 1"
                  >申请退款</van-button>
                  <van-button
                    size="small"
                    v-if="item.bc_go_btn == 2"
                    plain
                    hairline
                    @click="reFund(index)"
                  >申请售后</van-button>
                  <van-button v-if="item.is_service_btn" size="small" plain hairline @click="reFund(index)">{{item.is_service_btn}}</van-button>
                  <van-button v-if="item.is_service_btn2" size="small" plain hairline @click="cancelRefund(index)">{{item.is_service_btn2}}</van-button>
                  <van-button size="small" type="danger" plain hairline @click="toGoods(item.goods[0].goodsid, index)">再来一单</van-button>
                  <van-button size="small" @click="BuyAgen(item.id, index)">再次购买</van-button>
                </template>
              </van-cell>
              <van-cell :border="false"  v-if="item.is_saleservice_content && item.is_saleservice_content.length > 0" is-link class="shouH_cell" @click="serviceDetail(item.id)">
                <template #title>
                  <div class="red">
                    售后内容: {{item.is_saleservice_content}}
                    <van-tag class="copay_icon" v-if="item.is_saleservice > 0 && item.is_saleservice != 2" plain @click="addserviceInfo(index)" >{{(item.rtype == 2 || item.rtype == 1 || item.rtype == 3) && item.progress == 3 ? '添加单号': '回复'}}</van-tag>
                  </div>
                </template>
                <template #default>
                  <div>
                    查看
                  </div>
                </template>
              </van-cell>
              <van-cell v-if="item.expresssn" :title="'快递单号：' + item.expresssn">
                <template #right-icon>
                  <input type="text" v-model="item.expresssn" v-show="false" />
                  <van-button
                    size="small"
                    v-clipboard:copy="item.expresssn"
                    v-clipboard:success="expressnonCopy"
                    v-clipboard:error="expressnonError"
                  >复制</van-button>
                </template>
              </van-cell>
              <van-cell-group :border="false" class="address_info">
                <van-cell :border="false">
                  <template #title>
                    {{item.contact_name ? item.contact_name : ""}}
                  </template>
                  <template #default>
                    {{item.mobile ? item.mobile : ""}}
                    <van-button
                      v-clipboard:copy="item.contact_name + ' ' + item.mobile + ' ' + item.province + ' ' + item.city + ' ' + item.county + ' ' + item.street"
                      v-clipboard:success="ordersnonCopy"
                      v-clipboard:error="ordersnError"
                      size="mini"
                    >
                      复制
                    </van-button>
                  </template>
                </van-cell>
                <van-cell :border="false" title title-class="tongchang">
                  <template #title>
                    {{item.province ? item.province : ""}}
                    {{item.city ? item.city : ""}}
                    {{item.county ? item.county : ""}}
                    {{item.street ? item.street : item.address}}
                  </template>
                </van-cell>
              </van-cell-group>
              <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
                <van-button
                  block
                  icon="service-o"
                  @click="qiyukefu(index)"
                >
                  联系客服
                </van-button>
              </div>
            </div>
          </van-list>
        </div>
        <div class="main"  v-if="shownodata[orderactive]">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
    </van-tabs>
    <!-- 底部按钮 -->
    <div class="cart_bottom" v-if="orderactive == 1 && list.length > 0 ? true : false">
      <van-submit-bar
        :price="totalPrice"
        button-text="去支付"
        @submit="onSubmit"
        :disabled="submitBtn"
      >
        <van-checkbox v-model="checked" @click="checkAll" ref="allCheck">
          全选
        </van-checkbox>
      </van-submit-bar>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderList",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      orderactive: 0, // 订单状态
      order: [[], [], [], [], [], []], // 订单列表
      loading: [false, false, false, false, false, false],
      finished: [false, false, false, false, false, false],
      currSize: 10, // 每次查询条数
      currOffset: [0, 0, 0, 0, 0, 0], // 查询到哪里了
      list: [], // 展示内容
      shareid: 0,
      merchid: 0,
      strId: "", // 水印
      shownodata: [false, false, false, false, false, false],
      totalPrice: 0, // 合并支付-合计金额
      submitBtn: true, // 合并支付-默认禁止点击
      checked: false, // 合并支付-是否全选
      result: [], // 合并支付-复选框合集
      iswx: false,
      code: null, // 微信传回来的code 值
      userinfo: {},
      showkefu: 0,
      upids: [], // 需要更新的订单
    };
  },
  mounted() {
    this.strId = localStorage.getItem("DK_STRID");
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    
    if (window.isWeixin()) {
      this.iswx = true;  // 在微信中打开
    }else{
      this.iswx = false; // 不在微信中打开
    }
    let newopenid = null;
    newopenid = localStorage.getItem("DK_NEW_OPENID") ? localStorage.getItem("DK_NEW_OPENID") : false;

    if (!newopenid && this.iswx) {
      // 用户授权后获取code值
      this.code = this.$route.query.code ? this.$route.query.code : null;
      
      if (this.code) {
        this.getwxlog(this.code);
      }else{
        this.getopenid();
      }
    }
    // this.init();
  },
  activated() {
    console.log(this.$route.query.status, this.orderactive, "orderactive");
    if (parseInt(this.$route.query.status) != this.orderactive && this.list.length > 0) {
      this.orderactive = parseInt(this.$route.query.status);
      this.loading[this.orderactive] = true;
      this.currOffset[this.orderactive] = 0;
    }
    this.orderactive = parseInt(this.$route.query.status);
    this.loading[this.orderactive] = true;
    this.currOffset[this.orderactive] = 0;
    this.init();
    this.$parent.getmerchname(this.merchid, "订单列表");
    if (window.isWeixin()) {
      this.iswx = true;  // 在微信中打开
    }else{
      this.iswx = false; // 不在微信中打开
    }
    setTimeout(() => {
      this.showkefu = localStorage.getItem("DK_SHOWKEFU");
    }, 500);
    this.updateorder();
  },
  methods: {
    init() {
      this.getOrderList();
      this.getuserinfo();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 获取用户信息
    getuserinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          if (response.data && response.data.id > 0) {
            _that.userinfo = response.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getOrderList() {
      let _that = null;
      _that = this;
      let UID = null;
      UID = localStorage.getItem("DK_UID"); // 用户身份公钥
      _that.$axios.defaults.headers.common["Authorization"] = UID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/get_order",
          _that.$qs.stringify({
            status: _that.orderactive,
            size: _that.currSize,
            offset: _that.currOffset[_that.orderactive],
            merchid: _that.merchid
          })
        )
        .then(response => {
          _that.loading[_that.orderactive] = false;
          if (response.data.code == 100000) {
            _that.order[_that.orderactive] = response.data.data;
            _that.list = response.data.data;
            if (response.data.data.length >= _that.currSize) {
              _that.currOffset[_that.orderactive] = _that.currSize;
              _that.finished[_that.orderactive] = false;
              _that.shownodata[_that.orderactive] = false;
            } else {
              _that.currOffset[_that.orderactive] = response.data.data.length;
              _that.finished[_that.orderactive] = true;
            }
          } else {
            _that.finished[_that.orderactive] = true;
            _that.shownodata[_that.orderactive] = true;
            _that.list = [];
          }
        })
        .catch(error => {
          _that.loading[_that.orderactive] = false;
          _that.finished[_that.orderactive] = true;
          _that.shownodata[_that.orderactive] = true;
        });
    },
    // 滚动加载
    onLoad() {
      let _that = null;
      _that = this;
      let UID = null;
      UID = localStorage.getItem("DK_UID"); // 用户身份公钥
      _that.$axios.defaults.headers.common["Authorization"] = UID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/get_order",
          _that.$qs.stringify({
            status: _that.orderactive,
            size: _that.currSize,
            offset: _that.currOffset[_that.orderactive],
            merchid: _that.merchid
          })
        )
        .then(response => {
          _that.loading[_that.orderactive] = false;
          if (response.data.code == 100000) {
            if (_that.order[_that.orderactive].length > 0) {
              response.data.data.forEach(item => {
                _that.order[_that.orderactive].push(item);
              });
              _that.list = _that.order[_that.orderactive];
            } else {
              _that.order[_that.orderactive] = response.data.data;
              _that.list = response.data.data;
            }
            if (response.data.data.length >= _that.currSize) {
              _that.currOffset[_that.orderactive] += _that.currSize;
              _that.finished[_that.orderactive] = false;
            } else {
              _that.currOffset[_that.orderactive] += response.data.data.length;
              _that.finished[_that.orderactive] = true;
            }
          } else {
            _that.finished[_that.orderactive] = true;
          }
        })
        .catch(error => {
          _that.loading[_that.orderactive] = false;
          _that.finished[_that.orderactive] = true;
          console.log(error);
        });
    },
    // 切换标签页
    tabsClick(name, title) {
      this.orderactive = name;
      console.log(name, title);
      this.$router.replace({ name: "OrderList", query: { status: name, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
      this.list = [];
      if (this.order[name].length <= 0 && !this.finished[name]) {
        this.getOrderList();
      } else {
        this.list = this.order[name];
      }
    },
    // 查看订单详情
    details(index) {
      let status = null;
      status = this.list[index].status;
      let id = 0;
      id = this.list[index].id;
      this.upids = [id];
      
      let title = "";
      if (status == 0) {
        title = "待付款";
      } else if (status == 1) {
        title = "待发货";
      } else if (status == 2) {
        title = "待收货";
      } else if (status == 3) {
        title = "已完成";
      } else if(status == 5) {
        title = "驳回";
      } else if(status == 6) {
        title = "售后";
      }else if(status == -1) {
        title = "已关闭";
      }
      this.upids = [id];
      console.log(this.upids);
      this.$router.push({
        name: "OrderDetails",
        query: { title: title, status: status, id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 }
      });
    },
    // 去支付
    toPay(id, index) {
      console.log("去支付", id, index, this.list[index]);
      this.upids = [id];
      let uid = 0; // 用户id
      let ordersn = ""; // 订单编号
      let money = 0; // 订单金额
      let orderid = 0; // 订单id
      uid = this.list[index].memberid;
      ordersn = this.list[index].ordersn;
      money = this.list[index].price;
      orderid = this.list[index].id;
      localStorage.setItem("ordersn", ordersn);
      localStorage.setItem("money", money);
      localStorage.setItem("orderid", orderid);
      this.$router.push({name: "CheckPayment", query: {shareid: this.shareid, merchid: this.merchid} });
      
    },
    // 取消订单
    cancelPay(id, index) {
      console.log(id, index);
      let _that = null;
      _that = this;
      this.$dialog.alert({
        title: "删除订单",
        message: "确定要删除订单?",
        showCancelButton: true,
        beforeClose: function(action, done) {
          if (action == "confirm") {
            _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
            _that.$axios
              .post(
                _that.$store.state.domain + "web/order/cancel",
                _that.$qs.stringify({
                  id: id
                })
              )
              .then(response => {
                console.log(response);
                if (response && response.data && response.data.code == 100000) {
                  console.log("取消订单成功");
                  _that.$toast(response.data.msg);
                  _that.list.splice(index, 1);
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
          if (action == "cancel") {
            console.log("取消按钮");
          }
          done();
        }
      });
    },

    // 催单
    cuidan(ordersn) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/reminderOrder",
          _that.$qs.stringify({
            ordersn: ordersn
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100001) {
            _that.$toast(res.data.msg ? res.data.msg : '请重新登陆')
          }else {
            _that.$dialog.alert({
              title: res.data.title ? res.data.title : "",
              message: res.data.msg ? res.data.msg : "",
              theme: 'round-button',
            }).then(() => {
              // on close
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 确认收货
    Receipt(id, index) {
      let _that = null;
      _that = this;
      this.$dialog.alert({
        title: "是否确认收货?",
        message: '请确认已收到商品',
        showCancelButton: true,
        beforeClose(action, done) {
          if (action == "confirm") {
            console.log("点击确认按钮");
            _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
            _that.$axios
              .post(
                _that.$store.state.domain + "web/order/receipt",
                _that.$qs.stringify({
                  id: id
                })
              )
              .then(response => {
                console.log(response);
                if (response.data.code == 100000) {
                  _that.$notify({
                    type: "success",
                    message: response.data.msg ? response.data.msg : "收货成功"
                  });
                  _that.list.splice(index, 1);
                }else {
                  _that.$notify({
                    type: "warning",
                    message: response.data.msg ? response.data.msg : "收货失败"
                  });
                }
              })
              .catch(err => {
                console.log(err);
              })
          }
          if (action == "cancel") {
            console.log("点击取消按钮");
          }
          done();
        }
      });
    },
    // 复制快递单号
    expressnonCopy() {
      this.$toast("复制成功");
    },
    // 快递单号复制失败
    expressnonError() {
      this.$toast("复制失败");
    },
    // 复制订单号
    ordersnonCopy() {
      this.$toast("复制成功");
    },
    // 复制订单号
    ordersnError() {
      this.$toast("复制失败");
    },
    // 显示选择搜索类别
    showChecked() {
      this.showList = true;
    },
    // 执行搜索
    onSearch() {
      this.$router.push({name: "OrderSearch", query: { shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 再来一单
    toGoods(gid, index){
      console.log(gid, index);
      this.$router.push({name: "Details", query: { id: gid, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 合并支付-全选
    checkAll() {
      if (this.result.length < this.list.length) {
        this.$refs.checkboxGroup.toggleAll(true);
        this.$refs.allCheck.toggle(true);
        this.submitBtn = false; // 启用提交按钮
      } else {
        this.$refs.checkboxGroup.toggleAll(false);
        this.$refs.allCheck.toggle(false);
        this.submitBtn = true; // 禁用提交按钮
      }
      // 计算总金额
      this.sumtotal();
    },
    addserviceInfo(index) {
      this.$router.push({
        name: "serviceDetail",
        query: {
          id: this.list[index].id,
          shareid: this.shareid,
          merchid: this.merchid
        } 
      });
    },
    serviceDetail(id) {
      this.$router.push({
        name: "serviceDetail",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        } 
      });
    },
    // 合并支付-去支付
    onSubmit() {
      console.log("去支付");
      
      let ordersn = "";
      let oids = "";
      this.upids = [];
      if (this.result.length > 0) {
        this.result.forEach(item => {
          ordersn += this.list[item].ordersn + ",";
          oids += this.list[item].id + ",";
          this.upids.push(this.list[item].id);
        })
        if (ordersn.indexOf(",") >= 0) {
          ordersn = ordersn.substring(0, ordersn.length - 1);
        }
        if (oids.indexOf(",") >= 0) {
          oids = oids.substring(0, oids.length - 1);
        }
      }

      let price = 0;
      price = this.totalPrice / 100;
      
      localStorage.setItem("ordersn", ordersn);
      localStorage.setItem("money", price);
      localStorage.setItem("orderid", oids);
      
      let newopenid = "";
      newopenid = localStorage.getItem("DK_NEW_OPENID") ? localStorage.getItem("DK_NEW_OPENID") : false;

      if (this.iswx) {
        if (newopenid) {
          this.goPay();
        }else{
          this.getopenid();
        }
      }else{
        this.goPay();
      }
      
    },
    // 合并支付-单选
    checkboxChange(index) {
      // 改变复选框状态
      this.$refs.checkboxes[index].toggle();
      if (this.result.indexOf(index) >= 0) {
        this.result.splice(this.result.indexOf(index), 1);
        this.upids.splice(this.upids.indexOf(this.list[index].id), 1);
      } else {
        this.result.push(index);
        this.upids.push(this.list[index].id);
      }
      if (this.result.length > 0) {
        this.submitBtn = false; // 启用提交按钮
      }else{
        this.submitBtn = true; // 禁用提交按钮
      }
      // 计算总金额
      this.sumtotal();
    },

    /* 合并支付-计算总金额 */
    sumtotal() {
      this.totalPrice = 0;
      if (this.result.length > 0) {
        this.result.forEach(item => {
          this.totalPrice += parseFloat(this.list[item].price)*100;
        })
      }
    },

    /**
     * 微信code值
     */
    getopenid() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      let openid = null;
      openid = localStorage.getItem("DK_NEW_OPENID") ? localStorage.getItem("DK_NEW_OPENID") : false;

      /* 在微信中打开页面 */
      if (!openid && this.iswx) { 
        const wxAuthUrl = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx65fad56bebb47a1e&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";

          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },

    /**
     * 获取微信openID
     */
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_new_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            localStorage.setItem("DK_NEW_OPENID", response.data); // 获得用户的openid
            /* 保存openID */
            // _that.saveOpenid();
            // _that.goPay();
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 保存openid
    saveOpenid() {
      let _that = null;
      _that = this;
      let mid = 0;
      mid = localStorage.getItem("DK_MEMBERID") ? localStorage.getItem("DK_MEMBERID") : 0;
      let openid = null;
      openid = localStorage.getItem("DK_NEW_OPENID");
      if (parseInt(mid) > 0 && openid) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/pay/setopenid",
            _that.$qs.stringify({
              openid: openid,
              mid: mid,
              type: "yunfu"
            })
          )
          .then(response => {
            if (response.data.code == 100000) {
              // _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
    // 跳转到支付页面
    goPay() {
      let _that = null;
      _that = this;
      let mid = 0;
      mid = localStorage.getItem("DK_MEMBERID") ? localStorage.getItem("DK_MEMBERID") : 0;
      this.$router.push({name: "CheckPayment", query: {shareid: _that.shareid, merchid: _that.merchid} });
    },

    // 退款记录
    reFundHistory(id) {
      this.$router.push({
        name: "refundHistory",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      });
    },

    // 申请退款
    miniTuikuan(id) {
      this.$router.push({
        name: "miniTuikuan",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      });
    },

    // 申请退款按钮
    reFund(index) {
      this.upids = [this.list[index].id]; // 需要更新的订单
      if((this.list[index].status == 1 && (this.list[index]["is_outorder"] == 0 || this.list[index]["is_outorder"] == 3)) || this.list[index]["check_status"] == 2) {
        this.$router.push({
          name: "OrderRefund",
          query: {
            id: this.list[index].id,
            money: this.list[index].price,
            status: this.list[index].status,
            shareid: this.shareid,
            merchid: this.merchid
          } 
        });
      }else{

        if (this.list[index]["is_saleservice"] > 0 && this.list[index]["is_saleservice"] != 2) {
          this.$router.push({
            name: "serviceDetail",
            query: {
              id: this.list[index].id,
              shareid: this.shareid,
              merchid: this.merchid
            } 
          });
        }else {
          if(this.list[index]["check_status"] == 2) {
            this.$router.push({
              name: "miniTuikuan",
              query: {
                id: this.list[index].id,
                shareid: this.shareid,
                merchid: this.merchid
              }
            })
          }else {
            this.$router.push({
              name: "RefundOrderOne",
              query: {
                id: this.list[index].id,
                shareid: this.shareid,
                merchid: this.merchid
              } 
            });
          }
        }
      }
    },
    // 撤销退款
    cancelRefund(index) {
      // let _that = null;
      // _that = this;
      // _that.$axios
      //   .post(
      //     _that.$store.state.domain + "web/order/cancel_refund",
      //     _that.$qs.stringify({
      //       orderid: _that.list[index].id,
      //       refundid: _that.list[index].refundid
      //     })
      //   )
      //   .then(res => {
      //     console.log(res);
      //     if (res.data.code == 100000) {
      //       _that.$toast(res.data.msg ? res.data.msg : "撤销成功");
      //       _that.$router.go(0); // 刷新当前页
      //     }else{
      //       _that.$toast(res.data.msg ? res.data.msg : "撤销失败");
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   })
      this.$router.push({
        name: "serviceDetail",
        query: {
          id: this.list[index].id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },
    // 更新订单
    updateorder() {
      console.log("更新订单");
      if (this.upids.length > 0) {
        let _that = null;
        _that = this;
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/order/get_order_byid",
            _that.$qs.stringify({
              id: _that.upids
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.list.forEach((item, index) => {
                res.data.data.forEach((val, key) => {
                  if (val.id == item.id) {
                    if (val.refundid > 0 && val.refundstate > 0) {
                      _that.list.splice(index, 1);
                      _that.order[_that.orderactive].splice(index, 1);
                    }
                  }
                })
              })
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 七鱼客服
    qiyukefu(index) {
      let _that = null;
      _that = this;
      let order = {};
      order = _that.list[index];

      if ((_that.merchid == 0 || this.showkefu == 1)) {
        let uid = 0;
        let name = "未登录—游客";
        let agentCode = "";
        let level = 1;
        let avatar = "";
        let levelname = "普通会员";
        let mobile = "";
        if (_that.userinfo && _that.userinfo.id > 0) {
          if (_that.userinfo.level == '6') {
            level = 2;
          }else if (_that.userinfo.level == '1') {
            level = 3;
          }else if (_that.userinfo.level == '14') {
            level = 4;
          }else if (_that.userinfo.level == '5') {
            level = 5;
          }
          uid = _that.userinfo.id;
          name = _that.userinfo.nickname;
          agentCode = _that.userinfo.agent_code;
          avatar = _that.userinfo.avatar;
          if (_that.userinfo.levelname) {
            levelname = _that.userinfo.levelname;
          }
          mobile = _that.userinfo.mobile;
        }else{
          _that.$toast("请先登录");
          return false;
        }

        if(_that.userinfo.id > 0) {
          let str = "";
          let desc = "";
          str = JSON.stringify([
            {"index":0, "key":"avatar","label":"头像","value":avatar},
            {"index":1, "key":"agent_code", "label":"邀请码", "value":agentCode},
            {"index":2, "key":"level", "label":"等级", "value":levelname},
            {"index":3, "key":"shopname", "label":"商户名称", "value":localStorage.getItem("DK_SHOPNAME")},
            {"index":4, "key":"mobile", "label":"手机号", "value":mobile},
            {"index":5, "key":"shopid", "label":"商户id", "value":_that.merchid},
            {"index":6, "key":"shareid", "label":"分享者id", "value":_that.shareid}
          ]);
          desc = JSON.stringify([{title: order.ordersn + "【" + order.surplus.time_str + "】"}, {picture: order.goods[0].goodsthumb}]);
          window.location.href = "https://kefu.midbest.cn/wap/?uid=" + _that.userinfo.id + "&sessionform=" + str + "&card=" + desc + "&fromType=2";
          return false;
        }
      }else{
        this.$toast("页面还未加载完，稍等一下");
      }
    }
  }
};
</script>
<style lang="less">
.orderlist {
  background-color: #ededed;
  width: 100%;
  min-height: 100%;
  .van-nav-bar {
    .van-nav-bar__left {
      .van-icon {
        color: #333;
      }
    }
    .van-nav-bar__title{
      max-width: 74%;
      width: 74%;
      margin: 0 13%;
      .van-search{
        padding: 0px 12px 0 0;
        .van-cell__value{
          flex: 1;
        }
        .van-cell__right-icon{
          line-height: 30px;
          color: #333;
        }
        .van-cell__title{
          font-size: 14px;
        }
        .van-cell {
          max-width: 70px;
          padding: 0;
          overflow: hidden;
          line-height: 30px;
        }
        .van-search__content {
          .van-cell {
            max-width: 100%;
          }
        }
      }

    }
  }
  .main {
    .order_list {
      margin-top: 10px;
      .van-cell {
        padding: 10px;
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        .van-checkbox {
          padding-right: 10px;
        }
        .van-button {
          margin-left: 10px;
        }
      }
      .van-cell::after {
        left: 0;
        right: 0;
      }
      .van-card {
        padding: 10px;
        text-align: left;
        margin-top: 0;
        background-color: #fff;
        .van-card__thumb img {
          border-radius: 4px;
        }
        .van-card__tag {
          top: inherit;
          left: inherit;
          bottom: 0;
          right: 0;
          background-color: #fff;
          color: #808080;
          font-size: 14px;
          line-height: 16px;
          padding: 0 2px;
          opacity: 0.5;
        }
      }
      .address_info {
        padding: 0;
        overflow: hidden;
        .van-cell__title{
          flex: none;
          padding-right: 10px;
        }
        .van-cell__value{
          flex: 1;
          text-align: left;
          color: #333;
        }
        .van-cell {
          &:first-of-type {
            padding-bottom: 0;
          }
          .van-cell__label{
            color: #333;
          }
        }
        .tongchang {
          flex: 1;
        }
      }
    }
    .red {
      color: #ee0a24;
    }
  }

  // 底部按钮
  .cart_bottom {
    position: fixed;
    left: 0;
    bottom: 50px;
    right: 0;
    background: #fff;
    min-height: 50px;
    .van-submit-bar {
      position: absolute;
      .van-submit-bar__bar {
        padding: 0 10px;
      }
    }
    .van-checkbox__icon--checked .van-icon {
      background-color: #d22625;
      border-color: #d22625;
    }
    .cart_edit {
      position: fixed;
      bottom: 50px;
      right: 0;
      left: 70px;
      padding: 0 10px;
      z-index: 101;
      background-color: #fff;
      height: 50px;
      line-height: 50px;
      text-align: right;
      .van-button {
        height: 36px;
        line-height: 34px;
        margin-left: 10px;
      }
    }
  }
}
</style>